import axios from 'axios';
import { environment } from '../../environment/Environment';

import {
  SET_BOOKS,
  SET_BOOK,
  EDIT_BOOK,
  REMOVE_BOOK,
  REMOVE_SECTION,
  EDIT_SECTION,
  ADD_SECTION,
  SET_COMPANIES,
  EDIT_COMPANY,
  REMOVE_COMPANY,
  EDIT_PAGE,
  REMOVE_PAGE,
  ADD_PAGE,
  SET_USERS,
  EDIT_USER,
  REMOVE_USER,
  GET_DOC_LIST,
} from './actionTypes';

const apiActionDispatcher = (url, method, callback, state, data, formData) =>
  new Promise((resolve, reject) => {
    const { token } = state.auth;
    if (!token) {
      reject(new Error('no token'));
      return;
    }
    axios({
      url: `${environment.host.base}/v3/admin/${url}`,
      method: method || 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        ...(!formData && { 'Content-Type': 'application/json' }),
      },
      ...(data && { data }),
      ...(formData && { body: formData }),
    })
      .then((result) => {
        if (result) {
          callback(result.data, resolve, reject);
        }
      })
      .catch((e) => {
        var message = e.message;
        try {
          message = e.response.data.message;
        } catch {}
        reject(message);
      });
  });

export const getBooks = () => (dispatch, getState) =>
  apiActionDispatcher(
    'getBooks',
    null,
    ({ root: books }, resolve, reject) => {
      if (books) {
        dispatch({ type: SET_BOOKS, payload: books });
        resolve();
      }
    },
    getState(),
  );

export const getBook = (id) => (dispatch, getState) =>
  apiActionDispatcher(
    `getBook/${id}`,
    null,
    ({ root: book }, resolve, reject) => {
      if (book[0]) {
        dispatch({ type: SET_BOOK, payload: book[0] });
        resolve();
      }
      reject();
    },
    getState(),
  );

export const createBook = (book) => (dispatch, getState) =>
  apiActionDispatcher(
    `createBook`,
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: SET_BOOK, payload: { ...book, id: result, sections: [] } });
        resolve();
      }
      reject();
    },
    getState(),
    book,
  );

export const editBook = (id, book) => (dispatch, getState) =>
  apiActionDispatcher(
    `editBook/${id}`,
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: EDIT_BOOK, payload: { ...book, id } });
        resolve();
      }
      reject();
    },
    getState(),
    book,
  );

export const deleteBook = (id) => (dispatch, getState) =>
  apiActionDispatcher(
    `deleteBook/${id}`,
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: REMOVE_BOOK, payload: { id } });
        resolve();
      }
      reject();
    },
    getState(),
  );

export const createSection = (bookId, section) => (dispatch, getState) =>
  apiActionDispatcher(
    'createSection',
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: ADD_SECTION, payload: { bookId, section, sectionId: result } });
        resolve(result);
      }
      reject();
    },
    getState(),
    section,
  );

export const editSection = (bookId, sectionId, section) => (dispatch, getState) =>
  apiActionDispatcher(
    `editSection/${sectionId}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: EDIT_SECTION, payload: { bookId, section } });
        resolve();
      }
      reject();
    },
    getState(),
    section,
  );

export const deleteSection = (bookId, sectionId) => (dispatch, getState) =>
  apiActionDispatcher(
    `deleteSection/${sectionId}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: REMOVE_SECTION, payload: { bookId, sectionId } });
        resolve();
      }
      reject();
    },
    getState(),
  );

export const createPage = (page, bookId) => (dispatch, getState) => {
  const formData = new FormData();
  Object.keys(page).forEach((key) => formData.append(key, page[key]));
  return apiActionDispatcher(
    `createPage`,
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: ADD_PAGE, payload: { bookId, page, pageId: result } });
        resolve();
      } else {
        reject();
      }
    },
    getState(),
    formData,
  );
};

export const editPage = (page, bookId) => (dispatch, getState) => {
  const formData = new FormData();
  Object.keys(page).forEach((key) => formData.append(key, page[key]));
  return apiActionDispatcher(
    `editPage/${page.id}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: EDIT_PAGE, payload: { bookId, page } });
        resolve();
      }
    },
    getState(),
    formData,
  );
};

export const deletePage = (page, bookId) => (dispatch, getState) => {
  return apiActionDispatcher(
    `deletePage/${page.id}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: REMOVE_PAGE, payload: { bookId, page } });
        resolve();
      }
      reject();
    },
    getState(),
  );
};

export const getCompanies = () => (dispatch, getState) =>
  apiActionDispatcher(
    'getCompanies',
    null,
    ({ result: companies }, resolve, reject) => {
      if (companies) {
        dispatch({ type: SET_COMPANIES, payload: companies });
        resolve();
      }
    },
    getState(),
  );

export const createCompany = (company) => (dispatch, getState) =>
  apiActionDispatcher(
    'createCompany',
    'POST',
    ({ success, result }, resolve, reject) => {
      if (success) {
        dispatch({ type: EDIT_COMPANY, payload: { company: { ...company, id: result } } });
        resolve(success);
      }
      reject();
    },
    getState(),
    company,
  );

export const deleteCompany = (id) => (dispatch, getState) =>
  apiActionDispatcher(
    `deleteCompany/${id}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: REMOVE_COMPANY, payload: { companyId: id } });
        resolve(success);
      }
      reject();
    },
    getState(),
  );

export const editCompany = (company) => (dispatch, getState) =>
  apiActionDispatcher(
    `editCompany/${company.id}`,
    'POST',
    ({ success, message }, resolve, reject) => {
      if (success) {
        dispatch({ type: EDIT_COMPANY, payload: { company } });
        resolve(success);
      }
      reject(message);
    },
    getState(),
    company,
  );

export const getUsers = () => (dispatch, getState) =>
  apiActionDispatcher(
    'getUsers',
    null,
    ({ result: users }, resolve, reject) => {
      if (users) {
        dispatch({ type: SET_USERS, payload: users });
        resolve();
      }
    },
    getState(),
  );

export const createUser = (user) => (dispatch, getState) =>
  apiActionDispatcher(
    'createUser',
    'POST',
    ({ success, result, message }, resolve, reject) => {
      if (success) {
        user.password = '';
        dispatch({ type: EDIT_USER, payload: { user: { ...user, id: result } } });
        resolve(success);
      }
      reject(message);
    },
    getState(),
    user,
  );

export const deleteUser = (id) => (dispatch, getState) =>
  apiActionDispatcher(
    `deleteUser/${id}`,
    'POST',
    ({ success }, resolve, reject) => {
      if (success) {
        dispatch({ type: REMOVE_USER, payload: { userId: id } });
        resolve(success);
      }
      reject();
    },
    getState(),
  );

export const editUser = (user) => (dispatch, getState) =>
  apiActionDispatcher(
    `editUser`,
    'POST',
    ({ success, message }, resolve, reject) => {
      if (success) {
        user.password = '';
        dispatch({ type: EDIT_USER, payload: { user } });
        resolve(success);
      }
      reject(message);
    },
    getState(),
    user,
  );

export const downloadFile = (filePath, fileName, fileUrl) => (dispatch, getState) => {
  const { token } = getState().auth;
  return new Promise((resolve, reject) => {
    fetch(`./v3/app/${filePath}/${fileUrl}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => (res.ok ? res.blob() : Promise.reject(new Error('File not found'))))
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
        resolve();
      })
      .catch(({ message }) => reject(message));
  });
};

export const getDocList = (bookId) => (dispatch, getState) => {
  const { token } = getState().auth;
  return new Promise((resolve, reject) => {
    fetch(`/v3/admin/getDocRevNoExport/${bookId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/csv',
      },
    })
      .then((res) => (res.ok ? res.blob() : Promise.reject(new Error('File not found'))))
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'doc_rev_list.csv');
        link.click();
        resolve();
      })
      .catch(({ message }) => reject(message));
  });
};
