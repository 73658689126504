import { SET_MESSAGE, HIDE_MESSAGE, SET_SECTION } from './actionTypes';

export const showMessage = (message, type = 'negative') => (dispatch) => {
  dispatch({ type: SET_MESSAGE, payload: { message, type } });
};

export const hideMessage = () => (dispatch) => {
  dispatch({ type: HIDE_MESSAGE });
};

export const setSelectedSection = (sectionId) => (dispatch) => {
  dispatch({ type: SET_SECTION, payload: sectionId });
};
