import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Button, Icon, Checkbox } from 'semantic-ui-react';
import { Tree, minimalTheme } from 'react-lazy-paginated-tree';

import { setSelectedSection } from '../../../redux/ui/actions';
import './styles.css';

const Buttons = ({ onAdd, onRemove, isPageSection, isRoot }) => (
  <>
    {!isPageSection && (
      <Button
        icon
        size="mini"
        style={{ color: 'green', textAlign: 'cetner', backgroundColor: 'transparent' }}
        onClick={(e) => onAdd(e)}
      >
        <Icon name="plus" />
      </Button>
    )}
    {!isRoot && (
      <Button
        icon
        size="mini"
        style={{
          color: 'red',
          textAlign: 'cetner',
          backgroundColor: 'transparent',
        }}
        onClick={(e) => onRemove(e)}
      >
        <Icon name="minus" />
      </Button>
    )}
  </>
);

const TreeView = ({ data, nested = false, onDelete, onAdd, setLoading }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    (_, node) => {
      const { id, type } = node;
      if (type === 0) return;
      dispatch(setSelectedSection({ id }));
    },
    [dispatch],
  );

  //This function is needed inorder to hide the default checkbox property of the TrevvView library
  const TreeCheckbox = ({ theme, node, selected: isSelected }) => {
    return null;
  };

  //This function overrides the icons and thems from the orginal expander
  const TreeExpander = ({ onClick: clickHandler, onKeyPress, expanded, theme }) => (
    <Button
      style={{ backgroundColor: 'transparent', ...theme.expanderStyle, marginRight: 7 }}
      className={theme.expanderClassName}
      icon
      onClick={clickHandler}
      onKeyPress={onKeyPress}
    >
      <Icon size="small" name={expanded ? 'chevron down' : 'chevron right'} />
    </Button>
  );

  const TreeBody = ({ theme, node, onKeyPress }) => {
    const isPageSection = node.type === 3 || node.type === 4 || node.type === 6 || node.type === 7;
    const isRoot = node.type === 0;
    return (
      <div style={{ marginLeft: 7 }}>
        <List.Item>
          {node.name}
          <Buttons
            onAdd={(e) => {
              e.stopPropagation();
              onAdd(node.id, node.type === 0 && node.type);
            }}
            onRemove={(e) => {
              e.stopPropagation();
              onDelete(node.id);
            }}
            isPageSection={isPageSection}
            isRoot={isRoot}
          />
        </List.Item>
      </div>
    );
  };

  return (
    <Tree
      nodes={data || []}
      useLocalState
      Checkbox={TreeCheckbox}
      Body={TreeBody}
      Expander={TreeExpander}
      theme={minimalTheme}
      selectCallback={(e, node) => onClick(null, node)}
    />
  );
};

export default TreeView;
