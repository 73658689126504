import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid, Header, Segment, Modal, Button, Popup } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSectionTree,
  selectSectionById,
  selectSectionPages,
  selectSectionsChildren,
  selectBookName,
} from '../../../redux/api/selectors';
import sectionTypes from '../../../constants/sectionTypes';
import TreeView from '../../general/TreeView';
import MainLayout from '../../layout/MainLayout';
import {
  getBook,
  createSection,
  editSection,
  deleteSection,
  getDocList,
} from '../../../redux/api/actions';
import SectionForm from '../../general/SectionForm';
import { showMessage, hideMessage, setSelectedSection } from '../../../redux/ui/actions';

const EditBookPage = ({ id }) => {
  const dispatch = useDispatch();
  const sectionTree = useSelector((state) => selectSectionTree(state, id));
  const bookName = useSelector((state) => selectBookName(state, id));
  const selectedSection = useSelector((state) => state.ui.selectedSection);
  const section =
    useSelector((state) => selectSectionById(state, id, selectedSection && selectedSection.id)) ||
    {};
  const pages =
    useSelector((state) => selectSectionPages(state, id, selectedSection && selectedSection.id)) ||
    [];
  const subSections =
    useSelector((state) =>
      selectSectionsChildren(state, id, selectedSection && selectedSection.id),
    ) || [];

  const [pageLoading, setPageLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  useEffect(() => {
    // if (!book) {
    dispatch(getBook(id));
    // }
  }, []);

  const onSubmit = useCallback(
    (s) => {
      setPageLoading(true);
      const newSection = { ...s, bi: id };
      dispatch(
        selectedSection.id === 'new'
          ? createSection(id, newSection)
          : editSection(id, section.i, newSection),
      )
        .then((res) => {
          if (selectedSection.id === 'new') {
            dispatch(setSelectedSection({ id: res }));
            setTimeout(() => dispatch(hideMessage()), 2000);
          }
          dispatch(showMessage('Success', 'positive'));
        })
        .catch((e) => dispatch(showMessage(e, 'negative')))
        .finally(() => {
          setPageLoading(false);
        });
    },
    [dispatch, selectedSection, id, section],
  );

  const onDeleteSection = useCallback(
    (sectionId) => {
      setPageLoading(true);
      dispatch(deleteSection(id, sectionId))
        .then(() => {
          dispatch(showMessage('Success', 'positive'));
          setTimeout(() => dispatch(hideMessage()), 2000);
        })
        .catch((err) => dispatch(showMessage('Error', 'negative')))
        .finally(() => {
          setPageLoading(false);
          // dispatch(getBook(id)).then(() => );
        });
    },
    [dispatch, id],
  );

  const onAddNewSection = useCallback(
    (parentId, type = null) => dispatch(setSelectedSection({ parentId, id: 'new', type })),
    [dispatch],
  );
  return (
    <MainLayout header={bookName} showAddButton={false} selected="books" loading={pageLoading}>
      <Modal size="small" open={showWarning}>
        <Modal.Header>Delete Section</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this section</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              // setSectionToDelete(null);
              setShowWarning(false);
            }}
            negative
          >
            No
          </Button>
          <Button
            onClick={() => {
              onDeleteSection(sectionToDelete);
              setShowWarning(false);
            }}
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
      <Grid style={{ height: '100%' }}>
        <Grid.Column style={{ height: '100%', overflowY: 'scroll' }} width={6}>
          <Popup
            content="Export document and revision numbers to CSV"
            trigger={
              <Button
                onClick={() => {
                  dispatch(getDocList(id));
                }}
                positive
                icon="download"
                labelPosition="right"
                content="Export"
              />
            }
          />
          <TreeView
            data={sectionTree}
            onDelete={(i) => {
              setSectionToDelete(i);
              setShowWarning(true);
            }}
            onAdd={onAddNewSection}
            setLoading={setSectionLoading}
          />
        </Grid.Column>
        <Grid.Column style={{ height: '100%', overflowY: 'auto' }} width={10}>
          <Container style={{ paddingLeft: 10 }}>
            {selectedSection ? (
              <Segment padded basic>
                <SectionForm
                  loading={pageLoading}
                  setLoading={setPageLoading}
                  bookId={id}
                  section={{
                    ...section,
                    ...(selectedSection.id === 'new' && {
                      ps: selectedSection.parentId,
                      ...(selectedSection.type === 0 && { t: sectionTypes.Swipe }),
                    }),
                  }}
                  pages={pages}
                  subSections={subSections}
                  onSubmit={onSubmit}
                  creating={selectedSection.id === 'new'}
                />
              </Segment>
            ) : (
              <Header style={{ textAlign: 'center' }}>Select a Section</Header>
            )}
          </Container>
        </Grid.Column>
      </Grid>
    </MainLayout>
  );
};

export default EditBookPage;
